import React from 'react';
import RootLayout from '../../layouts/RootLayout';
import HeroSection from '../../sections/solutions/components/HeroSection';
import Advantages from '../../sections/solutions/components/Advantages';
import ExpertSolutions from '../../sections/solutions/components/ExpertSolutions';
import TrustAuthenticateSection from '../../sections/solutions/kyc-aml-compliance/TrustAuthenticateSection';
import PrivacyProtections from '../../sections/solutions/kyc-aml-compliance/PrivacyProtections';
import Resources from '../../components/reusable/Resources';
import {
    KYC_AML_COMPLIANCE_PAGE_RESOURCES,
    KYC_AML_FAQ,
    COMMON_BOTTOM_BANNER_CONTENTS,
    SOLUTIONS_TEXT_TO_ANIMATE,
    KYC_AML_ADVANTAGES,
    KYC_SOLUTIONS,
    BACKGROUND_CHECKS,
} from '../../sections/solutions/utilities';
import HERO_ANIMATED_BG from '../../lotties/solutions_page_hero_bg.json';
import HERO_IMAGE from '../../images/product-assets/Solutions_Hero_image.svg';
import ProductFaq from '../../sections/Product/components/common/ProductFaq';
import BottomBanner from '../../components/reusable/BottomBanner';
import ProductSeo from '../../components/ProductSeo';
import Banner from '../../sections/solutions/kyc-aml-compliance/Banner';
import Vendors from '../../components/reusable/vendors/Index';
const Solutions = () => {
    return (
        <RootLayout>
            <ProductSeo
                title="KYC/AML Compliance"
                description="Streamline user onboarding and ensure regulatory compliance with KYC and AML compliance solutions featuring automated identity verification and criminal background check."
                ogImage={
                    'https://cdn.authenticating.com/public/website/logos/authenticate-main-open-graph-image.png'
                }
            />
            <HeroSection
                pageTitle="KYC & AML"
                heading="Streamlined Global KYC & Automated AML Compliance for"
                subHeading="Simplify Your KYC/AML Compliance with Authenticate®: Verify Users Instantly, Prevent Fraud, and Fulfill All Compliance Needs Effortlessly."
                textToAnimate={SOLUTIONS_TEXT_TO_ANIMATE}
                animatedBgImage={HERO_ANIMATED_BG}
                heroImage={HERO_IMAGE}
                animatedBg
            />
            <Vendors />
            <Advantages content={KYC_AML_ADVANTAGES} />
            <ExpertSolutions
                heading=" Secure and Simplify Compliance with Expert Solutions"
                data1={KYC_SOLUTIONS}
                data2={BACKGROUND_CHECKS}
                link1="/products/identity"
                link2="/products/criminal-background-checks"
            />
            <Banner />
            <TrustAuthenticateSection />
            <PrivacyProtections />
            <Resources item={KYC_AML_COMPLIANCE_PAGE_RESOURCES} page="KYC/AML" />
            <ProductFaq
                faqData={KYC_AML_FAQ}
                subHeading="Everything you need to know about KYC/AML Compliance."
                titleColor="#262626"
                animation
            />
            <BottomBanner data={COMMON_BOTTOM_BANNER_CONTENTS} />
        </RootLayout>
    );
};

export default Solutions;
